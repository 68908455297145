<template>
  <div>
    <Row>
      <i-col span="24">
        <Divider dashed><span class="divider-text">媒体待消耗统计</span></Divider>
        <Table
          stripe
          size="small"
          :data="taskSummary"
          :columns="dataColumns"
        ></Table>
        <Divider dashed><span class="divider-text">服务待消耗统计</span></Divider>
        <Table
          stripe
          size="small"
          :data="serviceSummary"
          :columns="serviceColumns"
        ></Table>
      </i-col>
    </Row>

  </div>
</template>

<script>
import { countAvailableProductQuantity, countAvailableServiceItemQuantity } from '@/api/scp/storedContract'
export default {
  data () {
    return {
      taskSummary: [],
      dataColumns: [
        { title: '产品', align: 'left', key: 'productName' },
        {
          title: '待消耗数量',
          align: 'right',
          key: 'quantity'
        }
      ],
      serviceSummary: [],
      serviceColumns: [
        { title: '服务名称', align: 'left', key: 'serviceItemName' },
        {
          title: '待消耗数量',
          align: 'right',
          key: 'quantity'
        }
      ]
    }
  },
  created () {
    this.initSummary()
  },
  methods: {
    initSummary () {
      countAvailableProductQuantity({ secondPartyId: this.secondPartyId }).then(res => {
        this.taskSummary = res
      })
      countAvailableServiceItemQuantity({ secondPartyId: this.secondPartyId }).then(res => {
        this.serviceSummary = res
      })
    }
  },
  computed: {
    secondPartyId () {
      return this.$store.state.storedValue.secondPartyId
    }
  },
  watch: {
    secondPartyId () {
      this.initSummary()
    }
  }
}
</script>
